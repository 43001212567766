<template>
  <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 py-3 items-center">
    <div class="bg-white w-full rounded-lg shadow pb-20">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex w-full">
          <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>

          <div class="text-xl font-bold text-green-700">{{$t('order_details')}}</div>
        </div>
      </div>

      <div id="detail">
        <div class="flex pr-5 pl-5 space-x-3">
          <div
            class="flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100"
          >
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-100">
                <i class="material-icons p-3 text-green-400 ">list_alt</i>
              </span>
              <div class="ml-5 w-full space-y-2">
                <div class="text-base mt-2 mb-2 font-semibold text-gray-700">
                  {{$t('detail')}}
                </div>
               <div class="text-sm text-gray-600">
                  {{$t('order_id')}} :
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.id }}
                  </span>
                </div>
                <div class="text-sm text-gray-600">
                  {{$t('store')}} :
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ (formData.seller && formData.seller.company)||(formData.seller && formData.seller.fullName) }}
                  </span>
                </div>
                <div class="text-sm text-gray-600">
                  {{$t('shipping_address')}} :

                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                 
                    {{ formData.customer.shippingAddress }}
                  </span>
                </div>
                <div class="text-sm text-gray-600">
                  {{$t('total_price')}} :
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.totalPrice }} <sup class="uppercase">{{$f.getcurrencyCodeByCountry(dataCountry,formData.customer.country) }}</sup>
                  </span>
                </div>
                <div class="text-sm text-gray-600">
                  {{$t('total_fees')}} :
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.totalFees }} <sup class="uppercase">{{$f.getcurrencyCodeByCountry(dataCountry,formData.customer.country) }}</sup>
                  </span>
                </div>
                 <div v-if="ststu==='Cancelled'" class="text-sm text-bold-600 font-bold">
                   {{$t('cancellation_reason')}} :
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ reason }}
                  </span>
                </div>
                <!--Confirmation date -->
                 <div v-if="consfirmDate" class="text-sm text-gray-600">
                 {{$t('confirmation_date')}} :
                  <span class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700" >
                    {{ consfirmDate }} 
                  </span>
                </div>
                <!--shippin date -->
                 <div v-if="shippDate" class="text-sm text-gray-600">
                  {{$t('shipping_date')}} :
                  <span class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                    {{shippDate }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!--******************** Customer information ********************-->
          <div
            class="flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100"
          >
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-100">
                <i class="material-icons p-3 text-green-400 ">person</i>
              </span>
              <div class="ml-5 w-full">
                <div class="text-base mt-2 mb-2 font-semibold text-gray-700">
                  {{$t('customer_information')}}
                </div>
                <div class="text-sm font-light text-gray-700">
                  <i class="material-icons text-xs text-gray-400">person</i>
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.customer.fullName }}
                  </span>
                </div>
                 <div class="text-sm font-light text-gray-700">
                  <i class="material-icons text-xs text-gray-400">location_city</i>
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ $f.getCountryNameByCode(options,formData.customer.country) }}
                  </span>
                </div>
                <div class="text-sm font-light text-gray-700">
                  <i class="material-icons text-xs text-gray-400">location_city</i>
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.customer.city }}
                  </span>
                </div>
                <div class="text-sm font-light text-gray-700">
                  <i class="material-icons text-xs text-gray-400">room</i>
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.customer.address }}
                  </span>
                </div>

                <div class="text-sm font-light text-gray-700">
                  <i class="material-icons text-xs text-gray-400">phone</i>
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.customer.phone }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex pr-5 pl-5 mt-6">
          <div
            class="flex justify-between w-full h-auto p-4 rounded-lg border-gray-100"
          >
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-100">
                <i class="material-icons p-3 text-green-400 ">local_mall</i>
              </span>
              <div class="ml-1 w-full p-2">
                <div class="text-base font-semibold text-gray-700">
                    {{$t('products_order')}}
                </div>

                <div class="w-full mt-4">
                  <table class="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th
                          class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
                        >
                          {{$t('product')}}
                        </th>
                        <th
                          class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
                        >
                            {{$t('quantity')}}
                        </th>
                        <th
                          class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
                        >
                          {{$t('price')}}
                        </th>
                        <th
                          class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
                        >
                            {{$t('actions')}}
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(item, index) in formData.details"
                      :key="item._id"
                    >
                      <tr :class="index % 2 == 0 ? 'bg-gray-100' : ''">
                        <td
                          class="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                        >
                          <span
                            class="flex text-gray-700 capitalize whitespace-no-wrap"
                          >
                            <span class="mt-1">{{ item.product.name }}</span>
                          </span>
                        </td>
                        <td
                          class="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                        >
                          <p class="text-gray-700 ml-6 whitespace-no-wrap">
                            <span class="font-semibold"
                              >{{ item.quantity }}
                            </span>
                          </p>
                        </td>
                        <td
                          class="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                        >
                          <span
                            class="justify-center text-xs text-center pl-2 pr-2 py-1 rounded-full text-gray-700"
                          >
                            <span
                              class="text-gray-800 font-semibold text-xs ml-1"
                            >
                              {{ item.unitPrice }}
                            </span>
                           <sup class="uppercase">{{$f.getcurrencyCodeByCountry(dataCountry,formData.customer.country) }}</sup>
                          </span>
                        </td>
                        <td
                          class="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                        >
                          <button
                            :class="$colors.actionTable"
                            class="rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1"
                            @click="ShowPopUpInformation(index,item.product)"
                          >
                            <i class="material-icons mx-1">remove_red_eye</i>
                          </button>
                        </td>
                      </tr>

                      <!--**************************************Debut Modal Change Shipping Address  and Status Order***************************-->

                       <vue-modal-2  :name="'modal_' + index" :headerOptions="{title: $t('detail_product')}" :noFooter="true" modalSize="lg" @on-close="closePopUpInformation(index)">
                      <div class=" flex items-center lg:p-10 overflow-hidden relative">
                        <div class="w-full max-w-6xl rounded bg-white shadow-xl lg:p-20 mx-auto text-gray-800 relative md:text-left">
                            <div class="md:flex items-center">
                                <div class="w-full md:w-1/2 px-10 mb-10 md:mb-0">
                                    <div class="relative">
                                        <img :src="selectProduct.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="w-full relative z-10" alt="">
                                        <div class="border-4 border-yellow-200 absolute top-10 bottom-10 left-10 right-10 z-0"></div>
                                    </div>
                                </div>
                                <div class="w-full md:w-1/2 px-10">
                                    <div class="mb-10">
                                        <h1 class="font-bold uppercase text-2xl mb-5">{{selectProduct.name}}</h1>
                                        <p class="text-sm"> {{selectProduct.description}}
                              </p>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="modal-footer overflow-auto px-5 border0-t text-right">
                        <button @click="closePopUpInformation(index)"
                                    class="flex text-white mx-auto max-w-sm mb-2 rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right"
                                  >{{$t('close')}}</button>
                       </div> 
                     </vue-modal-2>

                      <!-- *************************************************************-->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="$f.getAccessPermission(currentUser, 'userHistories', 'read')"
          class="flex pr-5 pl-5 mt-6"
        >
          <div
            class="flex justify-between w-full h-auto p-4 rounded-lg border-gray-100"
          >
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-100">
                <i class="material-icons p-3 text-green-400">history</i>
              </span>
              <div class="ml-1 w-full p-2">
                <div class="text-base font-semibold text-gray-700">
                  Info History
                </div>

                <div class="w-full">
                  <div class="overflow-x-auto mt-4">
                    <div-table
                      :config="configList"
                      :data="dataHistories"
                      :loading="loading"
                      :paginate="paginate"
                      :DivFilter="false"
                      :idWharhouse="idWharhouse"
                      :currentUser="currentUser"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$f.getAccessPermission(currentUser,'statusHistories','read')" class="flex pr-5 pl-5 mt-6">
          <div
            class="flex justify-between w-full h-auto p-4 rounded-lg border-gray-100"
          >
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-100">
                <i class="material-icons p-3 text-green-400 ">history</i>
              </span>
              <div class="ml-1 w-full p-2">
                <div class="text-base font-semibold text-gray-700">
                  {{$t('status_history')}}
                </div>

                <div class="w-full">
                  <div class="overflow-x-auto mt-4">
                    <base-table
                      :content="content"
                      :headers="headers"
                      name="statusHistories"
                      :refresh="refresh"
                      :idWharhouse="idWharhouse"
                      :currentUser="currentUser"
                    >
                    </base-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import config from '../users/confighistory';
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
      configList: config.list,
      dataHistories: [],
      loading: false,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
      formData: {
        customer: {shippingAddress:null},
        details: [],
      },
       selectProduct:Object,
      show_modal: false,
      consfirmDate:null,
      shippDate:null,
       dataCountry: this.$countries,
      content: [],
      reason:"",
      ststu:"",
      headers: [
        "date",
        "user",
        "previousStatus",
        "status",
        "dateDebut",
        "timeConsumed",
        "comment",
      ],
      //currentUser: {},
      options: this.$countries,
    };
  },
  async mounted() {
    if (this.$route.params.id) {
      const data = await this.$server.get("orders", {
        id: this.$route.params.id,
      });
        this.formData = data.content;
        this.ststu=this.formData.status.name
        this.reason=this.formData.comment;

        if (this.$f.getAccessPermission(this.currentUser, 'userHistories', 'read')) await this.getUserHistory({ 'model.id': this.$route.params.id });

        if (this.currentUser.type === "Seller") {
          let pos = this.headers.map(function (e) {return e;}).indexOf('user');
          if(pos!=-1) this.headers.splice(pos, 1);           
        }
    }
   // await this.getUser();
     await this.getConfirmAndShippDate();
  },
  methods: {
      RouterBack: function() {
                this.$router.back();
            },
    async getConfirmAndShippDate(){
      const statusId = await this.getStatusByName("Confirmed");
       const res = await this.$server.get("statusHistories", {
        order: this.$route.params.id,
        status:statusId
      });
       if (res.content && res.content!="statusHistories not found") {
          this.consfirmDate=this.$moment(res.content.date).format('yyyy-MM-DD HH:mm'); 
       }

       const resShipp = await this.$server.get("shippings", {
        order: this.$route.params.id
      });
       if (resShipp.content && resShipp.content!="shippings not found") {
          this.shippDate=this.$moment(resShipp.content.date).format('yyyy-MM-DD'); 
       }
    },
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    /*******************************************Modal (View) update Chapping address and Status Order ************************************/
    ShowPopUpInformation(index,product) {
      this.selectProduct=product;
      let modal_id = "modal_" + index;
      this.$vm2.open(modal_id);
    },
    async closePopUpInformation(index) {
      let modal_id = "modal_" + index;
      this.$vm2.close(modal_id);
    },
    async getStatusByName(name) {
            const result = await this.$server.get("status",{ name: name });
            return result.content._id;
    },

    /**************************Fin Modal ****************************** */

    async getStatusHistories() {
      const filter = {
        order: this.$route.params.id,
        limit: 100,
      };
      const res = await this.$server.search("statusHistories", filter);
      if (res.content.results) {
        this.content = res.content.results;
        let statusHistories = this.content;
        for (let i in statusHistories) {
          statusHistories[i].user = statusHistories[i].user?statusHistories[i].user.fullName:'Système';
          statusHistories[i].date =this.$moment(statusHistories[i].date).format('yyyy-MM-DD  HH:mm');
          if(statusHistories[i].dateDebut) statusHistories[i].dateDebut =this.$moment(statusHistories[i].dateDebut).format('yyyy-MM-DD HH:mm');
          statusHistories[i].previousStatus =statusHistories[i].previousStatus.name;
          statusHistories[i].status = statusHistories[i].status.name;
          statusHistories[i].order = statusHistories[i].order._id;
        }

        this.content = statusHistories;
      } else this.content = [];
    },
    async refresh() {
      this.getStatusHistories();
    },
    async getUserHistory(filters) {
      this.loading = true;
      filters.limit = 100;
      const res = await this.$server.search('userHistories', filters);
      this.loading = false;
      if (res && res.content.results) {
        this.dataHistories = res.content.results;
        for (const i in this.dataHistories) {
          this.dataHistories[i].date = this.$moment(
            this.dataHistories[i].date,
          ).format('yyyy-MM-DD  HH:mm');
        }
      } else {
        this.dataHistories = [];
      }
    },
  },
};
</script>

<style>
/* animation for vue transition tag */
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}
dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
